<template>
    <div class="content">
        <a-row justify="space-between" type="flex">
            <a-col :span="4">

                <!--        <a-button type="primary" @click="start">播放</a-button>-->
                <a-space direction="vertical" size="large">
                    <a-space size="large">
                        <a-statistic-countdown :value="deadline" format="m 分 s 秒" title="剩余时间"/>
                        <a-button type="primary" @click="showVerifyPhone">验证并播放</a-button>
                    </a-space>
                    <a-descriptions :column="1">
                        <a-descriptions-item label="监控设备编号">
                            {{ this.$route.query.equipment.equipmentCode }}
                        </a-descriptions-item>
                        <a-descriptions-item label="设备名称">
                            {{ this.$route.query.equipment.information.name }}
                        </a-descriptions-item>
                        <a-descriptions-item label="采集点">
                            {{ this.$route.query.equipment.collectionPoint.pointName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="街道">
                            {{ this.$route.query.equipment.collectionPoint.address.streetName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="通道">
                            {{ this.$route.query.equipment.information.extend.channelTypeName }}
                        </a-descriptions-item>
                    </a-descriptions>
                </a-space>

                <a-divider/>

                <a-tree :replaceFields="{children:'children', title:'name', key:'code' }"
                        :tree-data="monitorTree"
                        style="height: 520px; overflow-y:auto"
                />
            </a-col>

            <a-col :span="20">
                <div id="playWnd" class="playWnd" style="height: 88vh"></div>
            </a-col>
        </a-row>

        <verify-phone ref="verifyPhone" @success="init"/>
    </div>
</template>

<script>
import verifyPhone from "./components/verifyPhone";
import {showCBInfo, WebControl} from "/public/preview/jsWebControl-1.0.0.min"
import {JSEncrypt} from '/public/preview/jsencrypt.min'
import _ from 'lodash'
import {queryAreaMonitorConfig, queryMonitorPreviewConf, queryMonitorTree} from "@/api/monitor-street";
import {getToken} from "@/utils/auth";
import crypto from 'crypto'
import moment from "moment";
import {queryMonitorEquipmentTree1,queryHikPreviewUrl} from "@/api/monitor";

export default {
    name: "monitorPreview",
    components: {
        verifyPhone
    },
    data() {
        return {
            oWebControl: undefined,
            monitorTree: [],
            handleWindowResize: _.debounce(() => {
                if (this.oWebControl) {
                    this.oWebControl.JS_Resize(document.querySelector("#playWnd").offsetWidth, document.querySelector("#playWnd").offsetHeight);
                }
            }, 50),
            time: 0,
        }
    },
    created() {
        this.queryMonitorTree()
    },
    mounted() {
        this.$refs.verifyPhone.toggleVisible()

        window.addEventListener('resize', this.handleWindowResize)
    },
    beforeDestroy() {
        this.stop()
        window.removeEventListener('resize', this.handleWindowResize)
    },
    methods: {
        showVerifyPhone() {
            this.$refs.verifyPhone.toggleVisible()
        },
        decrypt(encryptStrHex, code) {

            const token = getToken()

            const randomStr = code

            const md5 = crypto.createHash('md5')
            md5.update(token)
            md5.update(randomStr)

            const secretAll = md5.digest('hex')

            const secret = secretAll.substring(8, 24)

            const encryptStrBase = Buffer.from(encryptStrHex, 'hex').toString("base64")

            const decipher = crypto.createDecipheriv('aes-128-ecb', secret, '')
            let decryptData = decipher.update(encryptStrBase, 'base64', 'utf8')
            decryptData += decipher.final('utf8')
            return decryptData
        },
        queryAreaMonitorConfig() {
            queryAreaMonitorConfig().then(res => {
                this.timeCfg = res.result.second
            })
        },
        async init(serialCode, captcha) {
            this.queryAreaMonitorConfig()
            localStorage.setItem('serialCode', serialCode)
            localStorage.setItem('captcha', captcha)
            let res = (await queryMonitorPreviewConf({
                captcha: captcha,
                serialCode: serialCode,
            }))
            if (res.code != '0') {
                this.stop()
                this.time = 0
                this.$refs.verifyPhone.toggleVisible()
                return;
            }

            let data = res.result

            _.forEach(data, (value, key) => {
                data[key] = this.decrypt(value, captcha)
            })

            let initCount = 0
            let pubKey = ''
            let oWebControl

            // 推送消息
            let cbIntegrationCallBack = (oData) => {
                showCBInfo(JSON.stringify(oData.responseMsg))
            }

            //初始化
            let init = () => {
                getPubKey(() => {
                    ////////////////////////////////// 请自行修改以下变量值	////////////////////////////////////
                    const appkey = data.appKey                          //综合安防管理平台提供的appkey，必填
                    const secret = setEncrypt(data.appSecret)   //综合安防管理平台提供的secret，必填
                    const ip = data.ipAddr                           //综合安防管理平台IP地址，必填
                    const playMode = 0                                  //初始播放模式：0-预览，1-回放
                    const port = parseInt(data.host)                                    //综合安防管理平台端口，若启用HTTPS协议，默认443
                    const snapDir = "D:\\SnapDir"                       //抓图存储路径
                    const videoDir = "D:\\VideoDir"                     //紧急录像或录像剪辑存储路径
                    const layout = "1x1"                                //playMode指定模式的布局
                    const enableHTTPS = 1                               //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
                    const encryptedFields = 'secret'					   //加密字段，默认加密领域为secret
                    const showToolbar = 1                               //是否显示工具栏，0-不显示，非0-显示
                    const showSmart = 1                                 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
                    const buttonIDs = "0,16,256,257,258,259,260,512,513,514,515,516,517,768,769"  //自定义工具条按钮
                    ////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

                    oWebControl.JS_RequestInterface({
                        funcName: "init",
                        argument: JSON.stringify({
                            appkey: appkey,                            //API网关提供的appkey
                            secret: secret,                            //API网关提供的secret
                            ip: ip,                                    //API网关IP地址
                            playMode: playMode,                        //播放模式（决定显示预览还是回放界面）
                            port: port,                                //端口
                            snapDir: snapDir,                          //抓图存储路径
                            videoDir: videoDir,                        //紧急录像或录像剪辑存储路径
                            layout: layout,                            //布局
                            enableHTTPS: enableHTTPS,                  //是否启用HTTPS协议
                            encryptedFields: encryptedFields,          //加密字段
                            showToolbar: showToolbar,                  //是否显示工具栏
                            showSmart: showSmart,                      //是否显示智能信息
                            buttonIDs: buttonIDs                       //自定义工具条按钮
                        })
                    }).then((oData) => {
                        oWebControl.JS_Resize(document.querySelector("#playWnd").offsetWidth, document.querySelector("#playWnd").offsetHeight)  // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
                        this.oWebControl = oWebControl
                        this.start()
                    })
                })
            }

            //获取公钥
            let getPubKey = (callback) => {
                oWebControl.JS_RequestInterface({
                    funcName: "getRSAPubKey",
                    argument: JSON.stringify({
                        keyLength: 1024
                    })
                }).then((oData) => {
                    console.log(oData)
                    if (oData.responseMsg.data) {
                        pubKey = oData.responseMsg.data
                        callback()
                    }
                })
            }

            //RSA加密
            let setEncrypt = (value) => {
                var encrypt = new JSEncrypt()
                encrypt.setPublicKey(pubKey)
                return encrypt.encrypt(value)
            }

            // 创建播放实例
            let initPlugin = () => {
                const that = this

                oWebControl = new WebControl({
                    szPluginContainer: "playWnd",                       // 指定容器id
                    iServicePortStart: 15900,                           // 指定起止端口号，建议使用该值
                    iServicePortEnd: 15909,
                    szClassId: "23BF3B0A-2C56-4D97-9C03-0CB103AA8F11",   // 用于IE10使用ActiveX的clsid
                    cbConnectSuccess: () => {                     // 创建WebControl实例成功
                        oWebControl.JS_StartService("window", {         // WebControl实例创建成功后需要启动服务
                            dllPath: "./VideoPluginConnect.dll"         // 值"./VideoPluginConnect.dll"写死
                        }).then(() => {                           // 启动插件服务成功
                            oWebControl.JS_SetWindowControlCallback({   // 设置消息回调
                                cbIntegrationCallBack: cbIntegrationCallBack
                            })

                            oWebControl.JS_CreateWnd("playWnd", document.querySelector("#playWnd").offsetWidth, document.querySelector("#playWnd").offsetHeight).then(() => { //JS_CreateWnd创建视频播放窗口，宽高可设定
                                init()  // 创建播放实例成功后初始化
                            })
                        }, () => { // 启动插件服务失败
                        })
                    },
                    cbConnectError: () => { // 创建WebControl实例失败
                        oWebControl = null
                        WebControl.JS_WakeUp("VideoWebPlugin://") // 程序未启动时执行error函数，采用wakeup来启动程序
                        initCount++
                        if (initCount < 3) {
                            setTimeout(() => {
                                initPlugin()
                            }, 3000)
                        } else {
                        }
                    },
                    cbConnectClose: (bNormalClose) => {
                        // 异常断开：bNormalClose = false
                        // JS_Disconnect正常断开：bNormalClose = true
                        console.log("cbConnectClose")
                        oWebControl = null
                    }
                })
            }

            // initPlugin()
          this.start()
        },
       async start() {
            let cameraIndexCode = this.$route.query.equipmentCode     //获取输入的监控点编号值，必填
            const streamMode = 1                                     //主子码流标识：0-主码流，1-子码流
            const transMode = 1                                      //传输协议：0-UDP，1-TCP
            const gpuMode = 0                                        //是否启用GPU硬解，0-不启用，1-启用
            const wndId = -1                                         //播放窗口序号（在2x2以上布局下可指定播放窗口）

            cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, "")
            cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, "")

            // this.oWebControl.JS_RequestInterface({
            //     funcName: "startPreview",
            //     argument: JSON.stringify({
            //         cameraIndexCode: cameraIndexCode,                //监控点编号
            //         streamMode: streamMode,                         //主子码流标识
            //         transMode: transMode,                           //传输协议
            //         gpuMode: gpuMode,                               //是否开启GPU硬解
            //         wndId: wndId                                     //可指定播放窗口
            //     })
            // })

          const url = (await  queryHikPreviewUrl({
            "cameraIndexCode":this.$route.query.equipmentCode,
            "streamType":"1",
            "protocol":"hls",
            "transmode":0,
          })).result.data.url
          play(url,"playWnd","hls");

            this.time = 60

            setTimeout(() => {
                this.stop()
            }, this.time * 1000)
        },
        stop() {
          stopAll()
            if (this.oWebControl != null) {
                this.oWebControl.JS_HideWnd()   // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
                this.$refs.verifyPhone.toggleVisible()
                this.oWebControl.JS_Disconnect().then(() => {  // 断开与插件服务连接成功
                    },
                    () => {  // 断开与插件服务连接失败
                    })
            }
        },
        async queryMonitorTree() {
            this.monitorTree = (await queryMonitorTree()).result
        },
    },
    computed: {
        deadline() {
            return moment().add(this.time, 'seconds')
        }
    }
}
</script>

<style scoped>
.playWnd {
    /*margin: 30px 0 0 400px;*/
    /*width: 1000px; !*播放容器的宽和高设定*!*/
    height: 100%;
    border: 1px solid red;
}

.operate {
    margin-top: 24px;
}

.operate::after {
    content: '';
    display: block;
    clear: both;
}


.content {
    background-color: white;
    padding: 15px;
}

</style>

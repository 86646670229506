import service from './network';

export function queryMonitorList(data) {
    return service.request({
        url: '/street/monitor/query',
        method: 'post',
        data
    })
}

export function queryMonitorPreviewConf(data) {
    return service.request({
        url: '/hik/device/query/configure/image',
        method: 'post',
        data
    })
}

export function queryMonitorTree(data) {
    return service.request({
        url: '/street/monitor/tree',
        method: 'get',
    })
}

export function bindEquipmentApi(data){
    return service.request({
        url: '/equipment/bind/collect/point',
        method: 'post',
        data
    })
}

export function queryCollectionList(data){
    return service.request({
        url: '/collection/point/query',
        method: 'post',
        data
    })
}

export function queryAreaMonitorConfig(data) {
    return service.request({
        url: '/area/monitor/config',
        method: 'get',
        data
    })
}

export function queryAreaMonitorPicture(data) {
    return service.request({
        url: '/user/verification/picture',
        method: 'get',
    })
}

